import React from "react"
import { graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import styled from 'styled-components'

import Layout from "../components/layout-v2"
import SEO from "../components/seo"
import LabPostBuilder from "../components/lab-post-builder"

import SmallArrow from '../svgs/small-arrow.svg'
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, TwitterShareButton, TwitterIcon, } from "react-share";

const LabTemplate = ({ data: { labPost, allLabPosts } }) =>  {

    const layouts = labPost.LabPostBuilder.lpPageBuilder;
    const labPosts = allLabPosts.edges

    // Load Chance
    const Chance = require('chance');
    // Instantiate Chance so it can be used
    const chance = new Chance();
    //randomly pull two work posts for bottom cta
    const randLabPosts = chance.pickset(labPosts, 2);
    const twoLabPosts = randLabPosts

    return (
        <Layout>
          <SEO 
          title={labPost.SEOmeta.metaTitle} 
          description={labPost.SEOmeta.metaDescription}
          keywords={labPost.SEOmeta.metaKeywords}
          ogTitle={labPost.SEOmeta.ogTitle} 
          ogDescription={labPost.SEOmeta.ogDescription}
          ogImage={labPost.SEOmeta.ogImage.localFile.childImageSharp.fluid}
          twitterTitle={labPost.SEOmeta.twitterTitle} 
          twitterDescription={labPost.SEOmeta.twitterDescription}
          twitterImage={labPost.SEOmeta.twitterImage.localFile.childImageSharp.fluid}
          />
          <article style={{overflow: 'hidden'}}>
            <TitleSection>
              <div class="flex-section">
                <div>
                  <Link to="/lab/" className="desktop-hide"><SmallArrow/> Back to The Lab</Link>
                  <Img fluid={labPost.featuredImage.node.localFile.childImageSharp.fluid} alt={labPost.title}/>
                </div>
                <div>
                  <Link to="/lab/" className="mobile-hide"><SmallArrow/> Back to The Lab</Link>
                  <h1>{labPost.title}</h1>
                  <p>By {labPost.LabAuthor.labAuthorName}</p>
                </div>
              </div>
            </TitleSection>
            <div>
              {   
                  layouts.map((layout, index) => {
                      return <LabPostBuilder key={index} layoutData={layout} />
                  })
              }
            </div>
            <ShareFlex>
                <h4>Share</h4>
                <FacebookShareButton url={`https://wlion.com//work/${labPost.slug}/`} quote={labPost.title} >
                  <FacebookIcon size={64} round={false} iconFillColor={'#2b2520'} />
                </FacebookShareButton>
                <LinkedinShareButton url={`https://wlion.com//work/${labPost.slug}/`} title={labPost.title}>
                  <LinkedinIcon size={64} round={false} iconFillColor={'#2b2520'}/>
                </LinkedinShareButton>
                <TwitterShareButton url={`https://wlion.com//work/${labPost.slug}/`} title={labPost.title}>
                  <TwitterIcon size={64} round={false} iconFillColor={'#2b2520'}/>
                </TwitterShareButton>
            </ShareFlex>
            <MoreWorkSection>
              <h3>More Lab Intelligence</h3>
              {twoLabPosts.map(randomPost => (
                  <article
                  itemScope
                  itemType="http://schema.org/Article"
                  >
                      <Link to={`/lab/${randomPost.node.slug}/`} itemProp="url" >
                          {/* <Img fluid={randomPost.node.featuredImage.node.localFile.childImageSharp.fluid} alt={randomPost.node.title} className={"troubleshooting"}/> */}
                          <img src={randomPost.node.featuredImage.node.localFile.childImageSharp.fluid.src} alt={randomPost.node.title} class="normal-image"/>
                      </Link>
                      <Link to={`/lab/${randomPost.node.slug}/`}><h4 dangerouslySetInnerHTML={{ __html: randomPost.node.title }} itemProp="headline"/></Link>
                      <div class="excerpt" dangerouslySetInnerHTML={{ __html: randomPost.node.excerpt }} itemProp="description" />
                    <p class="author">By {randomPost.node.LabAuthor.labAuthorName}</p>
                  </article>
              ))}
            </MoreWorkSection>
          </article>
        </Layout>
    );
          
}

const TitleSection = styled.header`
  width: 100%;
  padding: 70px 0;
  position: relative;
  margin-bottom: 100px;
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 75%;
    top: 0;
    left: 35%;
    background-color: #EFEFEF;
  }
  .flex-section {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    > div {
      a {
        font-family: "Work Sans";
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
        color: #2B2520;
        margin: 0;
        text-decoration: none;
        display: block;
        width: 300px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        svg {
          color: #db4140;
            width: 24px;
            margin-left: 10px;
            transform: translateX(-10px) rotate(180deg);
            transition: all .3s cubic-bezier(.77,0,.175,1);
        }
        &:hover {
            svg {
                transform: translateX(-20px) rotate(180deg);
            }
        }
      }
      a.desktop-hide {
        display: none;
      }
      &:first-child {
        max-width: 500px;
        width: 100%;
        height: 467px;
        .gatsby-image-wrapper {
          height: 467px;
        }
      }
      &:last-child {
        max-width: 600px;
        width: 100%;
        h1 {
          font-size: 50px;
          line-height: 60px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        p {
          font-family: "Work Sans";
          font-size: 16px;
          line-height: 34px;
          font-weight: 500;
          color: #2B2520;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }
  @media(max-width:1230px) {
    .flex-section {
      max-width: 900px;
      > div {
        &:first-child {
          max-width: 400px;
          height: 400px;
          overflow: hidden;
        }
        &:last-child {
          max-width: 450px;
          h1 {
            font-size: 36px;
            line-height: 46px;
          }
        }
      }
    }
  }
  @media(max-width:900px) {
    padding: 30px 0;
    margin-bottom: 30px;
    &:after {
      display: none;
    }
    .flex-section {
      flex-wrap: wrap;
      > div {
        &:first-child {
          max-width: 100%;
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 30px;
        }
        &:last-child {
          max-width: 100%;
          background-color: #EFEFEF;
          padding: 40px 20px;
        }
      }
    }
  }
  @media(max-width:767px) {
    .flex-section {
      > div {
        a.mobile-hide {
          display: none;
        }
        a.desktop-hide {
          display: flex;
          justify-content: center;
          margin: 0 auto 30px;
        }
        &:first-child {
          height: auto;
          .gatsby-image-wrapper {
            height: 306px;
          }
        }
        &:last-child {
          h1 {
            margin-top: 0;
            margin-bottom: 0px;
            font-size: 30px;
            line-height: 36px;
          }
        }
      }
    }
  }
`

const ShareFlex = styled.section`
    max-width: 770px;
    margin: 0 auto;
    padding-top: 55px;
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-top: 10px solid #DB4140;
    h4 {
        width: 100%;
        font-family: "Work Sans";
        color: #777C7C;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 10px;
        text-align: center;
        text-transform: uppercase;
    }
    button {
      outline: 0;
      margin: 0 10px;
      transition: all .3s cubic-bezier(.77,0,.175,1);
      svg {
        rect {
          fill: transparent;
        }
      }
      &:hover {
        transform: translateY(-2px);
        svg {
          path {
            fill: #db4140;
          }
        }
      }
    }
`

const MoreWorkSection = styled.section`
    max-width: 1210px;
    width: 100%;
    margin: 100px auto;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    h3 {
        width: 100%;
        text-align: center;
        font-family: "Nobel",sans-serif;
        font-size: 40px;
        line-height: 56px;
        font-weight: 700;
        color: #2b2520;
        margin-top: 0;
        margin-bottom: 30px;
    }
    article {
        width: calc(50% - 15px);
        margin-bottom: 40px;
        a {
            text-decoration: none;
            color: #2b2520;
            &:first-child {
              display: inline-block;
              height: 340px;
              width: 100%;
              overflow: hidden;
            }
            > div {
                height: 340px;
                width: 100%;
                background-color: #aaa;
            }
        }
        h4 {
          width: 100%;
          font-family: "Nobel",sans-serif;
          font-size: 30px;
          line-height: 40px;
          font-weight: 700;
          color: #2b2520;
          margin-top: 10px;
          margin-bottom: 0px;
        }
        div.excerpt {
          p {
            font-family: "Work Sans";
            font-size: 18px;
            line-height: 28px;
            font-weight: 400;
            color: #777C7C;
            margin-top: 0;
            margin-bottom: 0px;
          }
        }
        p.author {
            font-family: "Work Sans";
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: #2B2520;
            margin-top: 5px;
            margin-bottom: 0px;
        }
        img {
          height: 340px;
          width: 100%;
          object-fit: cover;
          transition: opacity 500ms ease 0s, transform 1s 0s !important;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
          h4 {
              color: #db4140;
          }
      }
    }
    @media(max-width:1000px) {
      max-width: 700px;
      article {
        width: 100%;
      }
    }
    @media(max-width: 767px) {
      max-width: 500px;
      article {
        a {
          &:first-child {
            height: 275px;
          }
          > div {
            height: 275px;
          }
        }
        img {
          height: 275px;
        }
        h2 {
          font-size: 24px;
          line-height: 1.2;
        }
      }
    }
`

export default LabTemplate

export const pageQuery = graphql`
  query LabPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting all lab posts minus current post
    allLabPosts: allWpLabPost(filter: {id: {ne: $id}}) {
      edges {
        node {
            title
            slug
            excerpt
            databaseId
            LabAuthor {
              labAuthorName
            }
            featuredImage {
                node {
                  localFile {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                  }
                }
            }
        }
      }
    }
    # selecting the current work post by id
    labPost: wpLabPost(id: { eq: $id }) {
      id
      content
      title
      slug
      databaseId
      LabAuthor {
        labAuthorName
      }
      featuredImage {
        node {
          localFile {
              childImageSharp {
                fluid(maxWidth: 750, quality: 100) {
                    src
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
          }
        }
    }
      SEOmeta {
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        ogImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      LabPostBuilder {
        lpPageBuilder {
          __typename
          ... on WpLabPost_Labpostbuilder_LpPageBuilder_LpContentWithAccentLetter {
            fieldGroupName
            lpOneContent
          }
          ... on WpLabPost_Labpostbuilder_LpPageBuilder_LpContentBlock {
            fieldGroupName
            lpTwoContent
          }
          ... on WpLabPost_Labpostbuilder_LpPageBuilder_LpImageBlock {
            fieldGroupName
            lpThreeImage {
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1170, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpLabPost_Labpostbuilder_LpPageBuilder_LpFullWidthImage {
            fieldGroupName
            lpFourVariableHeight
            lpFourImage {
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`