// src/layouts/FullWidthImageLab/FullWidthImageLab.js

import React from "react"
import styled from 'styled-components'
import Img from "gatsby-image"

const FullWidthImageLab = ({ lpFourVariableHeight, lpFourImage }) => {

  return (
    <BlockContent style={{height: `${lpFourVariableHeight}px`}}>
      <div
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-delay="300"
      data-sal-easing="ease"
      >
        <Img fluid={lpFourImage.localFile.childImageSharp.fluid} alt={lpFourImage.title} style={{height: `${lpFourVariableHeight}px`}} />
      </div>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    width: 100%;
    margin: 70px auto;
    padding: 0;
    @media(max-width:800px) {
      height: auto !important;
      .gatsby-image-wrapper {
        height: auto !important;
        width: 100%;
        img {
          height: auto !important;
          width: 100%;
        }
      }
    }
    @media(max-width:767px) {
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      &.accented {
        border-left: none;
        padding: 0;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
`

export default FullWidthImageLab