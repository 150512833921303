// src/layouts/ContentBlockLab/ContentBlockLab.js

import React from "react"
import styled from 'styled-components'

import StartQuote from '../../images/start-quote.png'
import EndQuote from '../../images/end-quote.png'

const ContentBlockLab = ({ lpTwoContent }) => {

  return (
    <BlockContent>
        <div 
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
        dangerouslySetInnerHTML={{
          __html: lpTwoContent
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&#8221;/g, '"')
          .replace(/&#8220;/g, '"')
          .replace(' </ iframe', '</iframe')
          }} />
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 810px;
    width: 100%;
    margin: 0px auto;
    padding: 0 20px;
    h2 {
      font-family: "Nobel",sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 40px;
      line-height: 50px;
      color: #2b2520;
      margin-top: 40px;
      margin-bottom: 10px;
    }
    h3 {
      font-family: "Nobel",sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 30px;
      line-height: 40px;
      color: #2b2520;
      margin-top: 35px;
      margin-bottom: 30px;
  }
  h4 {
    font-family: "Nobel",sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 34px;
    color: #2b2520;
    margin-top: 25px;
    margin-bottom: 30px;
  }
    p,ul {
      font-family: "Work Sans";
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      color: #777c7c;
      margin-bottom: 30px;
    }
    ul {
      margin-left: 20px;
    }
    b, strong {
      color: #2B2520;
    }
    a {
      color: #DB4140;
      text-decoration: none;
    }
    blockquote {
      border: 5px solid #DB4140;
      padding: 80px 40px 60px;
      margin: 30px 0;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: 20px;
        height: 70px;
        width: 50px;
        background-image: url(${StartQuote});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: 20px;
        height: 70px;
        width: 50px;
        background-image: url(${EndQuote});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      p {
        font-family: "Work Sans";
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        line-height: 30px;
        color: #2B2520;
        cite {
          font-size: 18px;
          font-weight: 400;
          font-style: normal;
          line-height: 30px;
          color: #777C7C;
          position: relative;
          top: 20px;
        }
      }
    }
    @media(max-width:1000px) {
      max-width: 700px;
      padding: 20px 50px;
      flex-wrap: wrap;
      h2 {
          font-size: 44px;
          line-height: 1.3;
          margin-bottom: 10px;
          padding-left: 0;
      }
      h3 {
          font-size: 36px;
          line-height: 1.3;
          margin-bottom: 10px;
      }
      h4 {
          font-size: 28px;
          line-height: 1.3;
          margin-bottom: 10px;
      }
      p, ul {
        padding-left: 0;
      }
  }
  @media(max-width:767px) {
      max-width: 500px;
      padding: 0 20px;
      margin: 20px auto;
      h2 {
          font-size: 36px;
      }
      h3 {
          font-size: 28px;
      }
      h4 {
          font-size: 20px;
      }
      ul {
        margin-left: 0;
      }
      p, ul {
          font-size: 16px;
          line-height: 1.6;
      }
  }
`

export default ContentBlockLab