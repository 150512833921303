// src/layouts/ImageBlock/ImageBlock.js

import React from "react"
import styled from 'styled-components'
import Img from "gatsby-image"

const ImageBlock = ({ lpThreeImage }) => {

  return (
    <BlockContent>
      <div
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-delay="300"
      data-sal-easing="ease"
      >
        <Img fluid={lpThreeImage.localFile.childImageSharp.fluid} alt={lpThreeImage.title} />
      </div>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 1210px;
    width: 100%;
    margin: 70px auto;
    padding: 0 20px;
    @media(max-width:1000px) {
      max-width: 700px;
      flex-wrap: wrap;
    }
    @media(max-width:767px) {
      max-width: 500px;
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
    }
`

export default ImageBlock