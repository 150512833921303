// src/layouts/ContentWithAccentLetter/ContentWithAccentLetter.js

import React from "react"
import styled from 'styled-components'

const ContentWithAccentLetter = ({ lpOneContent }) => {

  return (
    <BlockContent>
        <div 
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
        dangerouslySetInnerHTML={{__html: lpOneContent}} />
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 810px;
    width: 100%;
    margin: 0px auto;
    padding: 0 20px;
    h2 {
      font-family: "Nobel",sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 40px;
      line-height: 50px;
      color: #2b2520;
      margin-top: 40px;
      margin-bottom: 10px;
    }
    h3 {
      font-family: "Nobel",sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 30px;
      line-height: 40px;
      color: #2b2520;
      margin-top: 35px;
      margin-bottom: 30px;
  }
  h4 {
      font-family: "Nobel",sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 24px;
      line-height: 34px;
      color: #2b2520;
      margin-top: 25px;
      margin-bottom: 30px;
  }
    p::first-letter {
      font-family: "Nobel",sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 60px;
      line-height: 60px;
      color: #DB4140;
      margin-top: 0;
      margin-bottom: 0;
      padding-right: 10px;
      padding-bottom: 0px;
      float: left;
    }
    p,ul {
      font-family: "Work Sans";
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      color: #777c7c;
      margin-bottom: 30px;
    }
    b, strong {
      color: #2B2520;
    }
    a {
      color: #DB4140;
      text-decoration: none;
    }
    ul {
      margin-left: 20px;
    }
    @media(max-width:1000px) {
      max-width: 700px;
      padding: 20px 50px;
      flex-wrap: wrap;
      h2 {
          font-size: 44px;
          line-height: 1.3;
          margin-bottom: 10px;
          padding-left: 0;
      }
      h3 {
          font-size: 36px;
          line-height: 1.3;
          margin-bottom: 10px;
      }
      h4 {
          font-size: 28px;
          line-height: 1.3;
          margin-bottom: 10px;
      }
      p, ul {
        padding-left: 0;
      }
  }
  @media(max-width:767px) {
      max-width: 500px;
      padding: 0 20px;
      margin: 20px auto;
      h2 {
          font-size: 36px;
      }
      h3 {
          font-size: 28px;
      }
      h4 {
          font-size: 20px;
      }
      ul {
        margin-left: 0;
      }
      p, ul {
          font-size: 16px;
          line-height: 1.6;
      }
  }
`

export default ContentWithAccentLetter